<template>
  <div class="wrapper">
    <div class="about-wrapper">
      <InteractiveImage class="profile-picture" :src="'me.jpg'"></InteractiveImage>
      <div class="about-text">
        <h1>Matěj Lajtkep</h1>
        <h3>Programátor</h3>
        <a class="href" href="https://www.linkedin.com/in/mat%C4%9Bj-lajtkep-687a831a2/"><fa :icon="['fab', 'linkedin']"></fa> Linked in</a>
      </div>
    </div>
    <div class="projects-wrapper-top">
      <div class="projects-wrapper-top-inner">
        Projekty
      </div>
    </div>
    <div class="projects-wrapper">
      <ProjectDisplay v-for="project in projects" :key="project.title" :description="project.description" :title="project.title" :imageSrc="project.imageSrc" :href="project.href"></ProjectDisplay>
    </div>
  </div>
</template>
<script>
import InteractiveImage from '@/components/InteractiveImage.vue'
import ProjectDisplay from '@/components/ProjectDisplay.vue'

export default {
  name: 'About',
  components: {
    InteractiveImage,
    ProjectDisplay
  },
  data() {
    return {
      projects: [{
        title: "Explozive bet",
        imageSrc: "/ProjectImages/explosiveBet.png",
        href: "ExplosiveBet",
        description: "Můj první projekt v PHP"
      },{
        title: "Age of war remake",
        imageSrc: "/ProjectImages/warTimes.png",
        href: "AgeOfWarRemake",
        description: "Remake mojí oblíbené hry v herním enginu Unity"
      },{
        title: "FTP klient",
        imageSrc: "/ProjectImages/desuFTPKlient.png",
        href: "FTPKlient",
        description: "Jednoduchý FTP klient napsaný v C#"
      }]
    }
  }
}
</script>

<style scoped>
  .href{
    text-decoration: none;
    color:black;
  }
  
  .wrapper{
    padding-top: 20px;
    width: clamp(700px, 50%, 1200px);
    height: fit-content;
    margin: auto;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .about-wrapper{
    width: 100%;
    height: 200px;
    background: var(--file-color);
    border-radius: 10px;
  }

  .projects-wrapper-top{
    width: 100%;
    margin-top: 50px;
    height: 35px;
  }

  .projects-wrapper-top-inner{
    font-size: 24px;
    padding-left: 18px;
    box-sizing: border-box;
    line-height: 35px;
    border-radius: 10px 10px 0px 0px;
    width: 200px;
    float: left;
    background: var(--file-color);
    height: 35px;
  }

  .projects-wrapper{
    border-radius: 0px 10px 10px 10px;
    margin-bottom: 50px;
    width: 100%;
    min-height: 200px;
    background: var(--file-color);
   
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
  }

  .profile-picture{
    border-radius: 10px;
    padding: 10px;
    height: 180px;
    width: 160px;
    float: left;
  }

  .about-text{
    padding: 10px;
    width: calc(100% - 200px);
    float: left;
  }
</style>