<template>
  <img :src="src">
</template>

<script>
export default {
  props: ['src'],
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>
