<template>
    <div class="project-display-wrapper" :style="`background-image: url('${imageSrc}')`">
      <router-link :to="`${href.toString()}`" class="href-element">
        <div>
          <h1>{{title}}</h1>
          <p>{{description}}</p>
        </div>
      </router-link>
    </div>
</template>

<script>
export default {
  props:{
    title: String,
    imageSrc: String,
    href: String,
    description: String
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.project-display-wrapper{
  margin: 10px;
  width: clamp(325px, calc(50% - 20px), 1200px);
  height: 200px;
  background: var(--file-color-darker);
  
  cursor: pointer;
  background-size: auto 120%;
  background-position: center;
}

.href-element{
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  width: 100%;
  height: 100%;
}

.href-element:hover div{
  background-color: rgba(0, 0, 0, 0.5);
}


.href-element div{
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.25);
}

.href-element h1{
  font-size: 24px;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-weight: bolder;
}

.href-element p{
  font-size: 12px;
  color: white;
  text-decoration: none;
  -webkit-text-stroke-width: 0.25px;
  -webkit-text-stroke-color: black;
}
</style>
